class MapFilter
  constructor: ->
    @mobileFilter = document.getElementById('mobile-filter')
    @providerFilters = document.getElementById('provider-filters')
    @filters = document.querySelectorAll('.filter')
    @map = document.getElementById('map')

    @setupFilters()

  setupFilters: ->
    @filters.forEach (elm) =>
      menu = elm.closest('.filter').querySelector('.menu')
      # Capture click
      label = elm.querySelector('.label')
      label.addEventListener 'click', (e) =>
        e.currentTarget.classList.toggle('activated')
        menu.classList.toggle('show')
        if window.innerWidth <= 992
          @handleMap(menu)
      # Capture click outside
      document.addEventListener 'click', (e) =>
        if !elm.contains(e.target) && menu.classList.contains('show')
          menu.classList.remove('show')
          if window.innerWidth <= 992
            @handleMap(menu)

          if @mobileFilter.classList.contains('activated')
            @mobileFilter.classList.toggle('activated')
            @providerFilters.classList.toggle('mobile-filters-active')

  handleMap: (menu) =>
    if menu.classList.contains('show')
      @map.style.display = "none"
    else
      @map.style.display = "block"
      document.body.style.removeProperty('overflow')

new MapFilter
